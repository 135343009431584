@import url('https://fonts.googleapis.com/css2?family=Lobster+Two:ital@1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&family=Roboto&display=swap" rel="stylesheet');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

  body {
    background: linear-gradient(324deg, rgb(42, 41, 41),  black,  rgb(54, 52, 52), #030303f4);
    background-size: 600% 600%;
    -webkit-animation: gradientAnimation 10s ease infinite;
    -moz-animation: gradientAnimation 10s ease infinite;
    -o-animation: gradientAnimation 10s ease infinite;
    animation: gradientAnimation 10s ease infinite;
    font-family: 'Courier New', Courier, monospace !important;
    font-size: 2vmin !important;
    
  }

  .features-list {
    background: linear-gradient(324deg, rgb(116, 12, 12),  rgb(92, 20, 20),  rgb(105, 13, 13), #3a0404f4);
    background-size: 600% 600%;
    -webkit-animation: gradientAnimation 10s ease infinite;
    -moz-animation: gradientAnimation 10s ease infinite;
    -o-animation: gradientAnimation 10s ease infinite;
    animation: gradientAnimation 10s ease infinite;
  }

  @-webkit-keyframes gradientAnimation {
  0%{background-position:15% 0%}
  50%{background-position:86% 100%}
  100%{background-position:15% 0%}
}
@-moz-keyframes gradientAnimation {
  0%{background-position:15% 0%}
  50%{background-position:86% 100%}
  100%{background-position:15% 0%}
}
@-o-keyframes gradientAnimation {
  0%{background-position:15% 0%}
  50%{background-position:86% 100%}
  100%{background-position:15% 0%}
}
@keyframes gradientAnimation {
  0%{background-position:15% 0%}
  50%{background-position:86% 100%}
  100%{background-position:15% 0%}
}

h1 {
  display: flex;
  font-size: 4vmin !important;
  -webkit-text-fill-color: silver !important;
  font-family: 'Bebas Neue', sans-serif !important;
  margin-bottom: 0pt;
}
    
h2 {
  display: flex;
  font-size: 4vmin !important;
  font-family: 'Bebas Neue', sans-serif !important;
  margin-bottom: 0pt;
  /* -webkit-text-fill-color: silver !important; */
}

h3 {
  display: flex;
  font-size: 3.75vmin !important;
  font-family: 'Bebas Neue', sans-serif !important;
  margin-bottom: 0pt;
  -webkit-text-fill-color: silver !important;
}

h4 {
  display: flex;
  font-size: 3vmin !important;
  font-family: 'Bebas Neue', sans-serif !important;
  margin-bottom: 0pt;
  -webkit-text-fill-color: silver !important;
}

h5 {
  display: flex;
  font-size: 2.5vmin !important;
  font-family: 'Bebas Neue', sans-serif !important;
  margin-bottom: 0pt;
  -webkit-text-fill-color: silver !important;
}

p {
  font-size: 2vmin !important;
  -webkit-text-fill-color: rgb(248, 240, 229) !important;
}

.section-title {
  -webkit-text-fill-color: silver !important;
}

.card {
  background: none !important;
  font-size: 2vmin !important;
  border: none !important;
}

.custom-border {
  background: url(../assets/circle.svg) !important;
  box-shadow: 0px 0px 10px white !important;
  background-size: 10px !important;
  background-repeat: round !important;
  overflow: hidden !important;
  resize: both !important;
  position: relative !important;
  min-width: 150px !important;
  min-height: 60px !important;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.custom-border::before {
  content: "" !important;
  position: absolute !important;
  top: 10px !important;
  left: 10px !important;
  right: 10px !important;
  bottom: 10px !important;
  background: linear-gradient(324deg, rgb(116, 12, 12), rgb(92, 20, 20), rgb(105, 13, 13), #3a0404f4) !important;
  background-size: 300% 300% !important;
  -webkit-animation: gradientAnimation 10s ease infinite !important;
  -moz-animation: gradientAnimation 10s ease infinite !important;
  -o-animation: gradientAnimation 10s ease infinite !important;
  animation: gradientAnimation 10s ease infinite !important;
  z-index: 1 !important; /* Ensures the pseudo-element is behind the content */
}

.custom-border:hover {
  transform: scale(1.1); /* Scale the card to 110% */
  box-shadow: 0px 0px 20px white !important; /* Enhance the shadow on hover */
}

.custom-border-directory {
  background: url(../assets/circle.svg) !important;
  box-shadow: 0px 0px 10px white !important;
  background-size: 10px !important;
  background-repeat: round !important;
  overflow: hidden !important;
  resize: both !important;
  position: relative !important;
  min-width: 150px !important;
  min-height: 60px !important;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  
}


.custom-border-directory::before {

  -webkit-animation: gradientAnimation 10s ease infinite !important;
  -moz-animation: gradientAnimation 10s ease infinite !important;
  -o-animation: gradientAnimation 10s ease infinite !important;
  animation: gradientAnimation 10s ease infinite !important;
  
}

.custom-border-directory:hover {
  transform: scale(1.1); /* Scale the card to 110% */
  box-shadow: 0px 0px 20px white !important; /* Enhance the shadow on hover */
}

.custom-border-crew {
  background: url(../assets/circle.svg) !important;
  box-shadow: 0px 0px 10px white !important;
  background-size: 10px !important;
  background-repeat: round !important;
  overflow: hidden !important;
  resize: both !important;
  position: relative !important;
  min-width: 150px !important;
  min-height: 60px !important;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.custom-border-crew::before {
  content: "" !important;
  position: absolute !important;
  top: 10px !important;
  left: 10px !important;
  right: 10px !important;
  bottom: 10px !important;
  background: linear-gradient(324deg, rgb(116, 12, 12), rgb(92, 20, 20), rgb(105, 13, 13), #3a0404f4) !important;
  background-size: 300% 300% !important;
  -webkit-animation: gradientAnimation 10s ease infinite !important;
  -moz-animation: gradientAnimation 10s ease infinite !important;
  -o-animation: gradientAnimation 10s ease infinite !important;
  animation: gradientAnimation 10s ease infinite !important;
  z-index: 1 !important; /* Ensures the pseudo-element is behind the content */
}


.custom-shop-border {
  box-shadow: 0px 0px 10px goldenrod!important;
  overflow: hidden !important;
  resize: both !important;
  position: relative !important;
  min-width: 150px !important;
  min-height: 60px !important;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.custom-shop-border::before {
 
  position: absolute !important;
  top: 10px !important;
  left: 10px !important;
  right: 10px !important;
  bottom: 10px !important;
  background: linear-gradient(324deg, rgb(116, 12, 12), rgb(92, 20, 20), rgb(105, 13, 13), #3a0404f4) !important;
  -webkit-animation: gradientAnimation 10s ease infinite !important;
  -moz-animation: gradientAnimation 10s ease infinite !important;
  -o-animation: gradientAnimation 10s ease infinite !important;
  animation: gradientAnimation 10s ease infinite !important;
  z-index: 1 !important; /* Ensures the pseudo-element is behind the content */
}

.custom-shop-border:hover {
  transform: scale(1.1); /* Scale the card to 110% */
  box-shadow: 0px 0px 20px goldenrod!important;/* Enhance the shadow on hover */
}


  

.card-body {
  border: none !important;
  box-shadow: 0px 0px 10px goldenrod!important;
  align-items: center;
  background: linear-gradient(324deg, rgb(116, 12, 12),  rgb(92, 20, 20),  rgb(105, 13, 13), #3a0404f4) !important;
    background-size: 300% 300% !important;
    -webkit-animation: gradientAnimation 10s ease infinite !important;
    -moz-animation: gradientAnimation 10s ease infinite !important;
    -o-animation: gradientAnimation 10s ease infinite !important;
    animation: gradientAnimation 10s ease infinite !important;
}

.ah-border {
  box-shadow: 0px 0px 20px rgb(255,0,0) !important;
}

.ah-text {
  font-weight: bold;
  -webkit-text-fill-color: black !important;
}
.ah-text-sub {
  
  -webkit-text-fill-color: black !important;
}

.card-body-page {
  box-shadow: none!important;
  margin-top: 2vmin !;
}

.card-body-cast {
  border: none !important;
  box-shadow: 0px 0px 10px silver!important;
  align-items: center;
  margin: 0px;
  padding: 5px;
}

.general-card {
  box-shadow: 0px 0px 10px goldenrod!important;
}

.about-card {
  box-shadow: 0px 0px 10px grey !important;
}

.about-card-edit {
  box-shadow: 0px 0px 10px white !important;
}

.process-card {
  box-shadow: 0px 0px 10px white !important;
  
}

.process-card-text {
  box-shadow: 0px 0px 10px white !important;
  
}

.process-card-text::placeholder {
  -webkit-text-fill-color: rgb(169, 163, 163) !important; /* Set the color you want for the placeholder */
  font-style: italic; /* You can customize other styles as well */
}

.card-img {
  position: relative;
  z-index: 2; /* Top layer */
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 12px;
  background: none !important;
  box-shadow: 0px 0px 10px white !important; /* Apply box-shadow only here */
}

.preview-container {
  width: 100% !important;
  height: 200px !important; ; 
  display: flex;
  align-items: center;
  justify-content: center;
}

.preview-container > * {
  width: 100% !important; /* Make children of the container take up 100% width */
  height: auto; /* Set height to auto for flexibility */
  margin: 0; /* Reset margin for children of the container */
  padding: 0 !important; /* Reset padding for children of the container */
}

.preview-card {
  width: 200px !important; /* Make children of the container take up 100% width */
  height: auto !important;; /* Set height to auto for flexibility */
  margin: 0 !important; /* Reset margin for children of the container */
  padding: 0 !important; /* Reset padding for children of the container */
}

.custom-card-width {
  width: 225px; /* Adjust this value to your desired width */
  background: black !important;
}

.modal-overlay {
  z-index: 1000; /* Or any value higher than the z-index of the card */
}

.model-popup {
  background: rgb(67, 66, 66) !important;
}

.hover-brighten {
  transition: background-color 0.5s ease !important;
}

.hover-brighten:hover {
  background-color: rgba(138, 120, 120, 0.2) !important;
}

.hover-brighten-hitchcock {
  transition: background-color 0.5s ease !important;
}

.hover-brighten-hitchcock:hover {
  background-color: rgb(251, 0, 0) !important;
}

.hover-brighten-back {
  transition: background-color 0.5s ease !important;
}

.hover-brighten-back:hover {
  background-color: rgb(30, 30, 30) !important;
}



.card-preview-text > * {
  font-size: 2vmin !important;
}

.list-group-item {
  background: none !important;
}

.card-app {
  /* max-width: 10rem;
  max-height: 10rem; */
  border-style: none !important;
  background-color: none !important;
}

.header-links a{
  text-decoration: none;
}

.centered-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-brand {
  font-family: 'Bebas Neue', sans-serif !important;
  -webkit-text-fill-color: silver !important;
  margin-left: 20px;
  font-size: 4vmin;
}

.nav-link{
  -webkit-text-fill-color: silver !important;
}

.navbar {
  background: black !important;
}

.navbar-toggler {
  margin-right: 20px;
  
}

.navbar-toggler-icon {
  font-size: 2vmin;
}

.navbar-collapse {
  margin-right: -20px; 
  font-size: 2vmin;
}

.nav-link {
  margin-left: 10px !important;
}

.navbar-brand {
  margin-left: 20px !important;
}

.button {
  background: rgb(67, 66, 66) !important;
  border-color: rgb(248, 240, 229) !important;
  border-style:ridge !important;
  font-family: 'Bebas Neue', sans-serif !important;
}

.contact-text {
  -webkit-text-fill-color: silver !important;
}

.contact-form {
  min-width:30rem !important;
  min-height: 20rem !important;
}

.about-us-header {
  margin-top: 5% !important;
  margin-bottom: 2% !important;
}

.mission-statement {
  margin-bottom: 20px;
}

.footer {
  background: black !important;
  max-width: 100%;
  padding: 10px; /* Adjust padding according to your design */
  margin-top: auto; /* Pushes the footer to the bottom */
  
  
}

.home-page-body {
 margin: 30px !important;
}

.text-content {
  position: absolute;
  top: 0px; 
  left: 40px; 
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  max-width: 400px; 
  max-height: 35rem;
}

.title-section {
  background-color: black !important;
}

.main-page-content {
  margin-left: 10%; 
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 2vmin !important;
}

.page-content {
  margin-left: 10%; 
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 2vmin !important;
}

.about-page-content {
  font-size: 2vmin !important;
  width: '100%';
  height: '100%';
}

.title-font {
  font-family: 'Bebas Neue', sans-serif !important;
  font-size: 7vmin !important;
}

.nld-font {
  font-family: 'Bebas Neue', sans-serif !important;
  font-size: 7vmin !important;
  -webkit-text-fill-color: rgba(20, 157, 20, 0.796) !important;
}

.yi-font {
  font-family: 'Bebas Neue', sans-serif !important;
  font-size: 7vmin !important;
  -webkit-text-fill-color: rgba(243, 230, 57, 0.796) !important;
}

.hhh-font {
  font-family: 'Bebas Neue', sans-serif !important;
  font-size: 7vmin !important;
  -webkit-text-fill-color: rgba(243, 131, 57, 0.992) !important;
}

.lsh-font {
  font-family: 'Bebas Neue', sans-serif !important;
  font-size: 7vmin !important;
  -webkit-text-fill-color: rgba(11, 85, 13, 0.992) !important;
}

.ps-font {
  font-family: 'Bebas Neue', sans-serif !important;
  font-size: 7vmin !important;
  -webkit-text-fill-color: rgba(221, 36, 32, 0.992) !important;
}

.main-title-button {
  background: rgb(67, 66, 66) !important;
  border-color: silver !important;  
}

.main-button {
  background-color: rgb(0, 0, 0) !important;
  border-color: silver !important;
}

.carousel-control-prev, .carousel-control-next {
  position: absolute !important;
 

  transform: translateY(40%) ;
  display: flex !important;

  width: 50px !important;
  height: 50px !important;
}

.carousel-control-prev {
  left: -10px !important; /* Moves prev button to the left, outside carousel */
}

.carousel-control-next {
  right: -10px !important; /* Moves next button to the right, outside carousel */
}

.carousel-indicators {
  display: none !important;
}

.carousel {
  padding-bottom: 10px !important; /* Extra space for indicators */
  padding-left: 40px !important; /* Extra space for prev button */
  padding-right: 40px !important; /* Extra space for next button */
  position: relative !important;
}


/* #video-container {
  position: relative; 
  margin: 15px;
  display: inline-block;
}

#video-container > video,
#video-container > canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#video-container > canvas {
  opacity: 0.8;
  pointer-events: none;
}

video {
  display: block;
  margin: auto;
  width: 100%;
  height: auto;
} */